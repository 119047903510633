import React, { useEffect } from 'react';
import { graphql, navigate } from 'gatsby';
import { getUser } from '../services/auth';
import axios from 'axios';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Row from '../components/row/row';
import { isLoggedIn } from '../services/auth';

export const query = graphql`
  query($id: String!) {
    strapiCourses(id: { eq: $id }) {
      id
      strapiId
      title
      slug
      category {
        id
        name
      }
    }
  }
`;

const Course = ({ data, ...props }) => {
  const course = data.strapiCourses;

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate('/app/login');
    }
  }, []);

  const handleComplete = async (event) => {
    event.preventDefault();
    // Get Course ID
    const courseId = course.strapiId;
    // Get User ID
    const userId = getUser().userId;
    const userJWT = getUser().jwt;
    // Get User Completed Courses
    const courseResults = await getUserCourses(userId);
    // Update User Completed Courses
    updateUserCompletedCourses(courseResults, courseId, userId, userJWT);
  };

  const updateUserCompletedCourses = (courseResults, courseId, userId, userJWT) => {
    axios
      .put(
        `${process.env.GATSBY_ADMIN_URL}/users/${userId}`,
        {
          completed_courses: [...courseResults, { id: courseId }],
        },
        {
          headers: {
            Authorization: `Bearer ${userJWT}`,
          },
        }
      )
      .then((response) => console.log(response))
      .catch((error) => console.log(error));
  };

  const getUserCourses = (userId) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.GATSBY_ADMIN_URL}/users/${userId}`)
        .then((response) => {
          resolve(response.data.completed_courses);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  return (
    <Layout>
      <SEO title={course.title} description="" />
      <Row className="wysiwyg-content">
        <h1 dangerouslySetInnerHTML={{ __html: course.title }} />
        <button onClick={handleComplete}>Complete Course</button>

        {/* {course.content.map(component => (
          <ReactMarkdown key={component.id} source={component.content} />
        ))} */}
      </Row>
    </Layout>
  );
};

export default Course;
